import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, ListGroup } from "react-bootstrap"
import { firestore, useFirestoreQuery, useAuth } from "gatsby-theme-firebase";

import Layout from "../components/layout"

const List = ({location}) => {
  var listId = '';
  if (location.state) {
    listId = location.state.listId;
  }
  const [users, isLoading] = useFirestoreQuery(
    firestore.collection("users").where('IsActive', '==', true),
  );

  const { isAuthLoading, isLoggedIn, profile } = useAuth();
  
  return (
    <Layout>
      <Container className="text-center">
        { isLoggedIn ? (
          <>
          <Row>
            <Col>
              <p>
                Group: Travel Party
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center my-3">
              <Col md="6">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <ListGroup>
                  {users.map(user => user &&
                      <ListGroup.Item >
                          {
                            <Link to={`/user/`} state={{ userId : `${user._id}` }} >{user.First} {user.Last}</Link>
                          }
                      </ListGroup.Item>)}
              </ListGroup>
                  )}
            </Col>
          </Row>
          </>
        ) : ( <><Row><Col>Please <Link to='/' >login</Link> to access this page.</Col></Row></> ) }
      </Container>
    </Layout>
  )}  

export default List
